import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { A, Box, Button, Section, Text, Title } from "../../components/Core";


const SectionStyled = styled(Section)`
  
`;

const OpqoCTA = () => {

  const data = useStaticQuery(graphql`
    query {
      tablet_hero: file(relativePath: { eq: "tablet_hero.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SectionStyled bg="primaryPurple" py={6}>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="5" className="my-5 my-lg-0">
              <div className="">
                <Box
                  className=" "
                  data-aos="fade-left"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Img fluid={data.tablet_hero.childImageSharp.fluid} />
                </Box>
              </div>
            </Col>
            <Col
              md="10"
              lg="7"
              className="mt-3 mt-lg-0"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div className="mt-4">
                <Title color="light">
                  In the time it took you to read this blog post...
                </Title>
                <Text color="lightShade">
                  You could have deployed Opqo, our game-changing mobile solution for Maximo.
                </Text>
                <Text color="lightShade" mt={3}>
                  Opqo is simple to acquire, simple to deploy and simple to use, with
                  clear transparent monthly pricing that is flexible to your usage.
                </Text>
              </div>
              <Box className="mt-4">
                <div className="d-flex flex-column align-items-left pt-3">
                  <A href="https://www.opqo.io" target="_blank" rel="noopener noreferrer">
                    <Button mb={2}>Check it out</Button>
                  </A>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default OpqoCTA;
