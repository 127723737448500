import Highlight, { defaultProps } from 'prism-react-renderer'
import github from 'prism-react-renderer/themes/github'
import React from 'react'


const copyToClipboard = str => {
	const el = document.createElement('textarea')
	el.value = str
	el.setAttribute('readonly', '')
	el.style.position = 'absolute'
	el.style.left = '-9999px'
	document.body.appendChild(el)
	el.select()
	document.execCommand('copy')
	document.body.removeChild(el)
}

const Wrapper = props => <div style={{ position: 'relative' }} {...props} />

const Button = props => (
	<button
		style={{
			border: 'none',
			boxShadow: 'none',
			textDecoration: 'none',
			position: 'absolute',
			top: 0,
			right: 0,
			margin: '0 8px 0 0',
			padding: '4px 8px',
			background: '#D5D9DF',
			color: '#6A6C6F',
			borderRadius: '0 0 4px 4px',
			cursor: 'pointer',
			fontSize: '12px',
			fontFamily: 'sans-serif',
			lineHeight: '1',
		}}
		{...props}
	/>
)

const Code = ({ codeString, children, language, ...props }) => {
	const [isCopied, setIsCopied] = React.useState(false)

	return (
		<Wrapper>
			<Highlight
				{...defaultProps}
				code={codeString}
				language={language}
				theme={github}
			>
				{({ className, style, tokens, getLineProps, getTokenProps }) => (
					<pre
						className={className}
						style={{
							...style,
							padding: '2rem',
							position: 'relative',
						}}
					>
						<Button
							onClick={() => {
								copyToClipboard(codeString)
								setIsCopied(true)
								setTimeout(() => setIsCopied(false), 3000)
							}}
						>
							{isCopied ? 'Copied!' : 'Copy'}
						</Button>

						{tokens.map((line, i) => (
							<div {...getLineProps({ line, key: i })} style={style}>
								{line.map((token, key) => (
									<span {...getTokenProps({ token, key })} />
								))}
							</div>
						))}
					</pre>
				)}
			</Highlight>
		</Wrapper>
	)
}

export default Code;