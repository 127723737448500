import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { preToCodeBlock } from "mdx-utils";
import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Code from "../components/Code";
import { A, Box, Section, Text, Title } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import OpqoCTA from '../sections/blog/OpqoCTA';
import he from 'he'

const H1 = styled.h1`
  margin-top: 60px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.secondary}
`;

const H2 = styled.h2`
  margin-top: 40px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.secondary}
`;

const H3 = styled.h3`
  margin-top: 30px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.secondary}
`;

const H4 = styled.h4`
  margin-top: 60px;
  margin-bottom: 16px;
`;

const H5 = styled.h5`
  margin-top: 60px;
  margin-bottom: 16px;
`;

const H6 = styled.h6`
  margin-top: 60px;
  margin-bottom: 16px;
`;

const Paragraph = styled.p`
  font-size: 17px;
  font-weight: 300;
  letter-spacing: -0.33px;
  line-height: 32px;
  margin-bottom: 16px;
`;

const UL = styled.ul`
  list-style-type: disc;
  margin-top: 28px;
  margin-bottom: 28px;
  li {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -0.33px;
    line-height: 32px;
    margin-left: 28px;
    margin-bottom: 16px;
  }  
`;

const OL = styled.ol`
  margin-top: 28px;
  margin-bottom: 28px;
  li {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -0.33px;
    line-height: 32px;
    margin-bottom: 16px;
  }  
`;

const BQ = styled.blockquote`
  padding:20px 40px 10px 30px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 6px solid ${({ theme }) => theme.colors.accentBlue};
  margin: 20px 0px;
`;

const TABLE = styled.table`
  padding:20px 40px 10px 30px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  margin: 20px 0px;
`;

const TD = styled.td`
  padding:20px 40px 10px 30px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
`

const TH = styled.th`
  padding:20px 40px 10px 30px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primaryPurple};
  font-size: 20px;
  
`

const mdxP = props => (
  <Paragraph {...props} />
)

const mdxA = props => (
  <A style={{ textDecoration: "underline", fontSize: "18px" }} {...props} />
)

const mdxUL = props => (
  <UL {...props} />
)

const mdxOL = props => (
  <OL {...props} />
)

const mdxBQ = props => (
  <BQ {...props} />
)

const mdxH1 = props => (
  <H1 {...props} />
)

const mdxH2 = props => (
  <H2 {...props} />
)

const mdxH3 = props => (
  <H3 {...props} />
)

const mdxH4 = props => (
  <H4 {...props} />
)

const mdxH5 = props => (
  <H5 {...props} />
)

const mdxH6 = props => (
  <H6 {...props} />
)

const mdxTable = props => (
  <TABLE {...props} />
)

const mdxTd = props => (
  <TD {...props} />
)

const mdxTh = props => (
  <TH {...props} />
)

export default class BlogPost extends React.Component {

  mdxComponents = {
    h1: mdxH1,
    h2: mdxH2,
    h3: mdxH3,
    h4: mdxH4,
    h5: mdxH5,
    h6: mdxH6,
    p: mdxP,
    a: mdxA,
    ul: mdxUL,
    ol: mdxOL,
    blockquote: mdxBQ,
    table: mdxTable,
    td: mdxTd,
    th: mdxTh,
    pre: (preProps) => {
      const props = preToCodeBlock(preProps)
      if (props) {
        return <Code {...props} />
      } else {
        return <pre {...preProps} />
      }
    },
  }

  render() {

    const { frontmatter, body } = this.props.data.mdx

    var cardImageURL = frontmatter.cardImage?.publicURL ?? frontmatter.featuredImage.publicURL
    var pageTitle = he.decode(frontmatter.pageTitle ?? frontmatter.title)

    return (
      <MDXProvider components={this.mdxComponents}>
        <PageWrapper footerDark="true">
          <Seo
            title={"Sharptree | Blog | " + pageTitle}
            description={he.decode(frontmatter.summary)}
            image={cardImageURL}
            twitterImage={cardImageURL}
            pathname={this.props.location.pathname}
          />

          <Section className="pb-0">
            <div className="pt-5"></div>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="12">
                  <Title variant="hero">
                    {he.decode(frontmatter.title)}
                  </Title>
                  <Box className="d-flex justify-content-center">
                    <Text mr={3}>
                      {frontmatter.date}
                    </Text>
                  </Box>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section className="position-relative" py={5}>
            <Container>
              <Row className="align-items-center justify-content-center">

                <Col lg="10" className="mb-5">
                  <MDXRenderer>{he.decode(body)}</MDXRenderer>
                </Col>

              </Row>

            </Container>
          </Section>

          <OpqoCTA />
        </PageWrapper>
      </MDXProvider>
    )
  }
}

export const query = graphql
  `
    query PostsByID($id: String!) {
      mdx(
          id: { eq: $id }
      ){
        body
        slug
        frontmatter {
          title
          pageTitle
          summary
          date(formatString: "LL")
          featuredImage {
            publicURL
          }
          cardImage {
            publicURL
          }
        }
      }
    }
`

